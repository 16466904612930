@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");

body {
  margin: 0;
  font-family: "Arial Nova";
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #023047;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#logo {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* .font-weight-bold {
  color: #ffffff;
  text-decoration: none;
} */
a:hover {
  color: #ffffff;
}

a {
  color: #a6e27c;
  text-decoration: none;
}

.Minter {
  max-width: 80vh;
  max-height: 80vh;
  padding: 10px 100px;
  margin: 0 auto;
  text-align: left;
}

input {
  border-top-style: grove;
  border-right-style: groove;
  border-left-style: groove;
  border-bottom-style: groove;
  font-size: 14px;
  width: 50%;
  line-height: 32px;
  border: 1px solid rgba(63, 63, 170, 0.1);
  display: block;
  margin: 0 auto;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}
li {
  padding: 4px;
}

#status {
  display: block;
  margin: 0 auto;
  align-items: center;
  color: #ffadad;
  text-align: center;
  margin-bottom: 4px;
}

button {
  padding: 9px 16px;
  max-height: 40px;
  /*box-shadow: 4px 4px 15px 0 rgb(0 0 0 / 20%);*/
  /* border-color: #fcbf49; */
  color: #a6e27c;
  background-color: #011824ff;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  border-width: 0;
}

button:hover {
  margin-top: -1px;
  color: #ffffff;
}

#title {
  font-size: 40px;
}

.uploadFile {
  /* border: 4px dashed rgba(4, 4, 5, 0.1); */
  /* border-radius: 16px; */
  text-align: center;
  padding: 32px 60px;
}
#line_breaks {
  border: 1px solid #686868;
}
#walletButton {
  text-align: right;
  float: center;
  margin-bottom: 2px;
  line-height: 16px;
  margin-top: 10px;
}

/* #walletButton:focus {
  outline: none;
  border: none;
}

#walletButton:hover {
  outline: none;
  border: none;
} */

#mintButton {
  text-align: right;
  float: center;
  margin-bottom: 2px;
  line-height: 16px;
  margin-top: 15px;
}

.image_tune {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
  height: 240px;
  display: inline-block;
}

.divclass {
  top: 8px;
  left: 25px;
}

.divParent {
  width: 150px;
  padding: 0px;
  margin: 0px;
}

.spanclass {
  text-align: center;
  font-size: 18px;
}

.vl {
  border-left: 6px solid green;
  height: 500px;
}

h2 {
  display: inline-block;
  clear: none;
}

.ans {
  padding-bottom: 2px;
  margin-top: 0px;
  font-weight: lighter;
  color: #a1a1a1;
}

.subtitle {
  color: #d8d8d8;
}

.ques {
  margin-top: 0px;
  margin-bottom: 1px;
  font-weight: bolder;
}

.love {
  text-align: center;
  color: #ffffff;
}
